import '../../assets/style/footer.css'

function Footer() {
    return (
        <>
            <footer>
                Website footer for catalog, information, quick contact
                <br/><a href="https://www.secorp.pro">www.secorp.pro</a>
            </footer>
        </>
    )
}

export default Footer;