

function About() {
    return (
        <>
            <main>
                About main
            </main>
        </>
    )
}

export default About;