async function addAndConnectWithGymaDB(gymaId, exerciseInput, type, count, sets, weight, minutes, km) {
    try {
        const response = await fetch(`/api/exercise/${gymaId}/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                exercise: exerciseInput,
                exercise_type: type,
                count: count,
                sets: sets,
                weight: weight,
                minutes: minutes,
                km: km
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to create new exercise session');
        }

        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error adding and connecting exercise with Gyma:', error);
        throw error;
    }
}

export { addAndConnectWithGymaDB };