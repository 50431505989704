import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/style/App.css'

import Home from "./pages/home/Home";
import Gymbro from "./pages/gymbro/Gymbro";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Navbar from "./components/app/Navbar";
import Footer from "./components/app/Footer";
import Header from "./components/app/Header";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/gymbro" element={<Gymbro />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/about" element={<About />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
