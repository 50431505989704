class Exercise {
    constructor(exercise, exercise_type, count, sets, weight, minutes, km) {
        this.exercise = exercise;
        this.exercise_type = exercise_type;
        this.count = count;
        this.sets = sets;
        this.weight = weight;
        this.minutes = minutes;
        this.km = km;
    }
}

export default Exercise;