import '../../assets/style/pokecard.css';
import logo12 from '../../assets/image/Logo12.png';
import { useRef, useState } from "react";

export default function PokeCard() {
    const logoImageRef = useRef(null);
    const [spinning, setSpinning] = useState(true);

    const handleHeaderTitleClick = () => {
        if (spinning) {
            logoImageRef.current.style.animationPlayState = 'paused';
            setSpinning(false);
        } else {
            logoImageRef.current.style.animationPlayState = 'running';
            setSpinning(true);
        }
    };

    return (
        <div className="poke-card">
            <div className="poke-picture" ref={logoImageRef} onClick={handleHeaderTitleClick}>
                <img src={logo12} alt="LOGO12"/>
            </div>
            <div className="poke-title">
                <h2>SeCorp</h2>
            </div>
            <div className="poke-text">
                <p><RainbowText text="SeCorp is a fun project of student Developer Sem Koldewijn"/></p>
            </div>
        </div>
    );
}

function RainbowText({text}) {
    const rainbowColors = ['red', 'orange', 'yellow', 'green', 'blue', 'indigo', 'violet'];

    const coloredLetters = text.split('').map((letter, index) => (
        <span key={index} style={{ color: rainbowColors[index % rainbowColors.length] }}>
            {letter}
        </span>
    ));

    return <>{coloredLetters}</>;
}