import {Link, useLocation} from "react-router-dom";
import '../../assets/style/navbar.css'

function Navbar() {
    const { pathname } = useLocation();

    const navItems = [
        { path: '/', title: 'Home' },
        { path: '/gymbro', title: 'Gymbro'},
        { path: '/contact', title: 'Contact' },
        { path: '/about', title: 'About' },
    ];

    return (
            <nav className="navbar">
                <ul>
                    {navItems.map((item) => (
                        <li key={item.path} className={pathname === item.path ? 'active' : ''}>
                            <Link to={item.path}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
            </nav>
    );
}

export default Navbar;