async function startGymaSession(gymbroName, password) {
    try {
        const response = await fetch('/api/gyma/start', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                person: gymbroName,
                key: password
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to start a new gyma session');
        }

        const data = await response.json();
        const newSession = data.session;

        return newSession;
    } catch (error) {
        console.error('Error starting new gyma session:', error);
        throw error;
    }
}

async function endGymaSession(gymaId) {
    try {
        const response = await fetch(`/api/gyma/${gymaId}/end`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to end the current gyma session');
        }

        const data = await response.json();
        const message = data.message;
        const endedSession = data.session;

        console.log(message);

        return endedSession;
    } catch (error) {
        console.error('Error ending the current gyma session:', error);
        throw error;
    }
}

async function getLastGymaSession() {
    try {
        const response = await fetch('/api/gyma/last', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to get the last gyma session');
        }

        const data = await response.json();

        const { lastGymaDTO, exercisesDTO } = data;
        console.log(data);
        return { lastGymaDTO, exercisesDTO };

    } catch (error) {
        console.error('Error getting the last gyma session:', error);
        throw error;
    }
}

export { startGymaSession, endGymaSession, getLastGymaSession };
