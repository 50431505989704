import Draggable from 'react-draggable';
import '../../assets/style/home.css';
import pokecard from './PokeCard';
import randomImage from './RandomImage';
import decimalClock from './DecimalClock';

function Home() {
    return (
        <>
            <main>
                <div className="widget-layer">
                    <Draggable>
                        {pokecard()}
                    </Draggable>
                    <Draggable>
                        {randomImage()}
                    </Draggable>
                    <Draggable>
                        {decimalClock()}
                    </Draggable>
                </div>
            </main>
        </>
    )
}

export default Home;