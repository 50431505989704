import { useState, useEffect } from 'react';
import Gyma from '../gymbro/models/GymaModel';
import Exercise from '../gymbro/models/ExerciseModel';
import { startGymaSession, endGymaSession } from './services/GymaService';
import { addAndConnectWithGymaDB } from './services/ExerciseService';
import HistoryController from './HistoryController';

function StateController() {
    const HCI = HistoryController();

    const [state, setState] = useState(() => {
        const storedState = localStorage.getItem('state');
        return storedState ? JSON.parse(storedState) : { gymaActive: false, exerciseActive: false };
    });
    const [current, setCurrent] = useState(() => {
        const storedCurrent = localStorage.getItem('current');
        return storedCurrent ? JSON.parse(storedCurrent) : { gyma: null, exerciseList: [] };
    });

    // Update localStorage whenever state changes
    useEffect(() => {
        localStorage.setItem('state', JSON.stringify(state));
    }, [state]);
    // Update localStorage whenever current state changes
    useEffect(() => {
        localStorage.setItem('current', JSON.stringify(current));
    }, [current]);

    const getGymaActive = () => {
        return state.gymaActive;
    };

    const getExerciseActive = () => {
        return state.exerciseActive;
    };

    const setGyma = async (gymbroName, key) => {
        try {
            const newSession = await startGymaSession(gymbroName, key);
            const gymaObj = new Gyma(newSession.id, newSession.person, newSession.timeOfArrival, newSession.timeOfLeaving);

            setState(prevState => ({
                ...prevState,
                gymaActive: true
            }));
            setCurrent(prevCurrent => ({
                ...prevCurrent,
                gyma: gymaObj
            }));
            console.debug('GymaObj set to current, gymaActive true, gyma: ', gymaObj);
        }
        catch(error) {
            console.log('Unable to retrieve session from DB, error: ', error)
            return error;
        }
    };

    const setExercise = async (exerciseInput, type, count, sets, weight, minutes, km) => {
        try {
            const ex = await addAndConnectWithGymaDB(getGyma().id,
                exerciseInput, type, count, sets, weight, minutes, km);

            const exerciseObj = new Exercise(ex.exercise, ex.exercise_type, ex.count, ex.sets, ex.weight, ex.minutes, ex.km);

            setState(prevState => ({
                ...prevState,
                exerciseActive: true
            }));
            setCurrent(prevCurrent => ({
                ...prevCurrent,
                exerciseList: [...prevCurrent.exerciseList, exerciseObj]
            }));
        } catch(error) {
            console.log('Unable to retrieve exercise from DB, error: ', error)
        }
    };

    const getGyma = () => {
        return current.gyma;
    };

    const getExercises = () => {
        return current.exerciseList;
    };
    const getCurrent = () => {
        return current;
    }

    const endGyma = async () => {
        try {
            const endedSession = await endGymaSession(getGyma().id);
            const gymaObj = new Gyma(endedSession.id, endedSession.person, endedSession.timeOfArrival, endedSession.timeOfLeaving);

            console.log('StateController endGyma: ', gymaObj)

            HCI.addCurrentToHistory({
                ...current,
                gyma: gymaObj
            });

            resetStateAndCurrent();
        } catch (error) {
            console.error('Error ending gyma session:', error);
        }
    }
    const resetStateAndCurrent = () => {
        setState({
            gymaActive: false,
            exerciseActive: false
        });
        setCurrent({
            gyma: null,
            exerciseList: []
        });
    }

    return {
        getGymaActive,
        getExerciseActive,
        setGyma,
        setExercise,
        getGyma,
        getExercises,
        endGyma
    };
}

export default StateController;
