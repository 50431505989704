import { useState, useEffect } from 'react';
import StateController from './StateController';
import HistoryController from './HistoryController';
import '../../assets/style/gymbro.css';
import '../../assets/style/gymaCard.css'
import '../../assets/style/startGyma.css'
import '../../assets/style/exerciseInputCard.css';

function Gymbro() {
    const SCI = StateController();
    const HCI = HistoryController();

    return (
        <main>
            <div className="gyma-section-active">
                {SCI.getGymaActive() ? (
                    <>
                        {exerciseInputCard()}
                        {displayGymaCard(SCI.getGyma(), SCI.getExercises())}
                        {endGymaButton()}
                    </>
                ) : (
                    startGyma()
                )}
            </div>
            <div className="gyma-section-server">
                <p>Server</p>
                {LatestServerGyma()}
            </div>
            <div className="gyma-section-history-local">
                <p>Gyma History Local</p>
                {historyCards()}
            </div>

        </main>
    )
    function LatestServerGyma() {
        const [loading, setLoading] = useState(true);
        const [gymaData, setGymaData] = useState(null);
        const [error, setError] = useState(null);

        useEffect(() => {
            async function fetchServerGyma() {
                try {
                    const { gyma, exercises } = await HCI.getLatestServerGyma();
                    if (gyma) {
                        setGymaData({ gyma, exercises });
                    } else {
                        setError('No server entries found');
                    }
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            }

            fetchServerGyma();
        }, []);

        if (loading) {return <p>Loading...</p>;}
        if (error) {return <p>Error: {error}</p>;}
        if (!gymaData) {return null;}

        return displayGymaCard(gymaData.gyma, gymaData.exercises);
    }


    function historyCards() {
        const historyEntries = HCI.getAllHistoryAsList(HCI.history);

        if (!Array.isArray(historyEntries) || historyEntries.length === 0) {
            return <div>No local history entries found.</div>;
        }

        return (
            <div>
                {historyEntries.map((entry, index) => (
                    <div key={index}>
                        {displayGymaCard(entry.gyma, entry.exercises)}
                    </div>
                ))}
            </div>
        );
    }

    function displayGymaCard(gymaObj, exerciseRows) {
        const {id, person, timeOfArrival, timeOfLeaving} = gymaObj;

        return (
            <div className="gyma-card">
                <div className="gyma-card-header">
                    <p>{id || 'No ID'}, {person || 'No Person'}</p>
                    <p>Time of Arrival: {timeOfArrival || 'Null'}</p>
                    <p>Time of Leaving: {timeOfLeaving || 'Null'}</p>
                </div>
                <div className="exercise-table-field">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Count or Minutes</th>
                        <th>Sets or Km</th>
                        <th>Weight</th>
                    </tr>
                    </thead>
                    <tbody>
                    {exerciseRows.map((exerciseObj, index) => displayExerciseRow(exerciseObj, index))}
                    </tbody>
                </div>
            </div>
        );
    }

    function displayExerciseRow(exerciseObj, index) {
        const {exercise, exercise_type, count, sets, weight, minutes, km} = exerciseObj;

        const onClickExercise = () => {
            const exerciseString = exerciseObj.exercise;

            navigator.clipboard.writeText(exerciseString)
                .then(() => {
                    console.log('Exercise object copied to clipboard:', exerciseString);
                })
                .catch((error) => {
                    console.error('Failed to copy exercise object to clipboard:', error);
                });
        };
        const onClickCount_Minutes = () => {
            console.log(`Clicked count: ${count}`);
            console.log(`Clicked minutes: ${minutes}`);
        };
        const onClickSets_Km = () => {
            console.log(`Clicked sets: ${sets}`);
            console.log(`Clicked km: ${km}`);
        };
        const onClickWeight = () => {
            console.log(`Clicked weight: ${weight}`);
        };

        return (
            <tr key={index} className={`exercise-row ${exercise_type}`}>
                <td onClick={onClickExercise}>{exercise}</td>
                <td onClick={onClickCount_Minutes}>{count !== null ? count : minutes !== null ? minutes : ''}</td>
                <td onClick={onClickSets_Km}>{sets !== null ? sets : km !== null ? km : ''}</td>
                <td onClick={onClickWeight}>{weight !== null ? weight : ''}</td>
            </tr>
        );
    }

    function startGyma() {
        const onInputGymbroName = () => {

        };
        const onClickStartGymaButton = () => {
            console.log("startBtn pressed")
            const gymbro = document.querySelector('.input-gymbro-name').value;
            const key = document.querySelector('.input-password').value;

            const error = SCI.setGyma(gymbro, key)
            if(error) {
                document.getElementById('error-starting').textContent = 'Failed to start Gyma because of: ' + error;
                document.getElementById('error-starting').style.color = 'red';
                document.getElementById('error-starting').style.display = 'block';
            }
        }
        return (
            <div className="gyma-card">
                <input className="input-gymbro-name" onInput={onInputGymbroName} placeholder="Your Name"/>
                <br/>
                <input className="input-password" type="password" placeholder="Password"/>
                <br/>
                <button className="startGymaButton" onClick={onClickStartGymaButton}>
                    Start Gyma Right Now
                </button>
                <p id="error-starting"/>
            </div>
        );
    }

    function exerciseInputCard() {
        const onInputExercise = () => {

        };
        const addExerciseBtn = () => {
            //scenario: exercise, first, second, third = gains
            //scenario: exercise, first, second = cardio
            //scenario: exercise, first = other

            const exerciseInput = document.querySelector('.exercise').value;
            const firstInput = document.querySelector('.first-field').value;
            const secondInput = document.querySelector('.second-field').value;
            const thirdInput = document.querySelector('.third-field').value;

            // Scenario: exercise, first, second, third = gains
            if (exerciseInput && firstInput && secondInput && thirdInput) {
                SCI.setExercise(exerciseInput, 'gains', firstInput, secondInput, thirdInput, null, null);
                console.log('Gains Exercise details passed to SCI.setExercise');
            }
            // Scenario: exercise, first, second = cardio
            else if (exerciseInput && firstInput && secondInput) {
                SCI.setExercise(exerciseInput, 'cardio', null, null, null, firstInput, secondInput);
                console.log('Cardio Exercise details passed to SCI.setExercise');
            }
            // Scenario: exercise, first = other
            else if (exerciseInput && firstInput) {
                SCI.setExercise(exerciseInput, 'other', null, null, null, firstInput, null);
                console.log('Other Exercise details passed to SCI.setExercise');
            }
            // Invalid scenario
            else {
                console.error('Invalid input scenario');
            }
        };


        return (
            <div className="gyma-card exercise-input-card">
                <div className="input-fields">
                    <input className="exercise" onInput={onInputExercise} placeholder="exercise"/>
                    <input className="first-field" placeholder="count or minutes"/>
                    <input className="second-field" placeholder="sets or km"/>
                    <input className="third-field" placeholder="weight"/>
                    <div className="plus-icon" onClick={addExerciseBtn}>+</div>
                </div>
            </div>
        );
    }
    function endGymaButton() {
        const onClickEndGymaButton = () => {
            SCI.endGyma();
        };
        return (
            <div className="gyma-card">
                <button className="startGymaButton" onClick={onClickEndGymaButton}>
                    End Gyma Session
                </button>
            </div>
        );
    }
}

export default Gymbro;