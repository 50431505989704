import React, { useState, useEffect } from 'react';
import '../../assets/style/randomImage.css';

export default function RandomImage() {
    const [randomImage, setRandomImage] = useState(null);

    useEffect(() => {
        getRandomImage();
    }, []);

    const getRandomImage = async () => {
        const pepeCornerImage = await import('../../assets/image/randomSquare/pepe-corner.png');
        const metroStairsDarkImage = await import('../../assets/image/randomSquare/metro-stairs-dark.jpg');

        const images = [pepeCornerImage.default, metroStairsDarkImage.default];

        const randomIndex = Math.floor(Math.random() * images.length);
        setRandomImage(images[randomIndex]);
    };

    return (
        <div className="random-image-frame">
            {randomImage && <img src={randomImage} alt="Random IMG" />}
        </div>
    );
}
