import { useState } from 'react';
import Gyma from '../gymbro/models/GymaModel';
import Exercise from '../gymbro/models/ExerciseModel';
import { getLastGymaSession } from './services/GymaService';

function HistoryController() {
    const [history, setHistory] = useState(() => {
        const storedHistory = localStorage.getItem('history');
        return storedHistory ? JSON.parse(storedHistory) : [];
    });

    const addCurrentToHistory = (current) => {
        const { gyma, exerciseList } = current;
        const newHistoryEntry = { gyma, exercises: exerciseList };

        setHistory(prevHistory => {
            const updatedHistory = { ...prevHistory, [gyma.timeOfLeaving]: newHistoryEntry };
            localStorage.setItem('history', JSON.stringify(updatedHistory));
            return updatedHistory;
        });
    };
    const getAllHistoryAsList = (history) => {
        return Object.values(history).map(entry => {
            if (entry.gyma) {
                return {
                    gyma: new Gyma(
                        entry.gyma.id,
                        entry.gyma.person,
                        entry.gyma.timeOfArrival,
                        entry.gyma.timeOfLeaving
                    ),
                    exercises: entry.exercises.map(exercise => new Exercise(
                        exercise.exercise,
                        exercise.exercise_type,
                        exercise.count,
                        exercise.sets,
                        exercise.weight,
                        exercise.minutes,
                        exercise.km
                    )),
                };
            } else {
                // Handle the case where gyma is undefined
                console.error("Gyma data is missing in history entry:", entry);
                return null; // Or handle this case according to your application logic
            }
        }).filter(entry => entry !== null); // Filter out any null entries
    };
    const getLatestServerGyma = async () => {
        try {
            const { lastGymaDTO, exercisesDTO } = await getLastGymaSession();
            if (lastGymaDTO && exercisesDTO) {
                const gyma = new Gyma(
                    lastGymaDTO.id,
                    lastGymaDTO.person,
                    lastGymaDTO.timeOfArrival,
                    lastGymaDTO.timeOfLeaving
                );

                const exercises = exercisesDTO.map(exercise => new Exercise(
                    exercise.exercise,
                    exercise.exercise_type,
                    exercise.count,
                    exercise.sets,
                    exercise.weight,
                    exercise.minutes,
                    exercise.km
                ));

                return { gyma, exercises };
            } else {
                return null;
            }
        } catch (error) {
            console.error('Error retrieving latest gyma session:', error);
            return null;
        }
    };


    return {
        history,
        addCurrentToHistory,
        getAllHistoryAsList,
        getLatestServerGyma,
    };
}

export default HistoryController;