

function Contact() {
    return (
        <>
            <main>
                Contact main
            </main>
        </>
    )
}

export default Contact;